/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function ($) {
    $('.dropdown-toggle').dropdown();

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                $(window).on('load resize', function () {
                    height_equalizer();
                });
                // Masonry
                var $grid = $('.masonry-grid').imagesLoaded(function () {
                    // init Masonry after all images have loaded
                    $grid.masonry({
                        // options
                        columnWidth: '.grid-sizer',
                        itemSelector: '.masonry-item',
                        percentPosition: true
                    });
                });
                /*
                 * AJAX ACTIONS
                 */
                $('body').on('click', '.action-get-more-trainings, .action-get-more-resources', function (e) {
                    e.preventDefault();
                    var $this = $(this);
                    var $page = (parseInt($this.data('page')) + 1);
                    var $grid_container = ($this.hasClass('action-get-more-resources')) ? $('#resources-grid') : $('#trainings-grid');
                    var $action = ($this.hasClass('action-get-more-resources')) ? 'get_more_resources' : 'get_more_trainings';
                    $.ajax({
                        url: rootsAjax.ajaxUrl,
                        type: 'POST',
                        data: {
                            nonce: rootsAjax.nonce,
                            action: $action,
                            paged: $page,
                        },
                        success: function (data) {
                            if (data.success) {
                                var $elems = $(data.html);
                                $grid_container.append($elems).masonry('appended', $elems);
                                // Load more btn
                                $this.data('page', $page);
                                if ($page >= $this.data('max-page')) {
                                    $this.remove();
                                }
                            }
                        }
                    });
                });
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

    // height_equalizer function
    function height_equalizer() {
        $('[data-equalizer]').each(function () {
            var blocks = ($(this).data('equalizer')) ? $('[data-equalizer-watch="' + $(this).data('equalizer') + '"]') : $('[data-equalizer-watch]', $(this));
            blocks.css('height', 'auto');
            if (!$(this).data('equalizer-mq') || isResolutionLargeEnough($(this).data('equalizer-mq'))) {
                var max_height = 0;
                blocks.each(function () {
                    if (!$(this).data('height')) {
                        $(this).data('height', $(this).height());
                    }
                    if ($(this).height() > max_height) {
                        max_height = $(this).height();
                    }
                });
                blocks.height(max_height);
            }
        });
    }

})(jQuery); // Fully reference jQuery after this point.


