(function ($) {
    var circle_animation_time = 500;

    $(window).on('load resize', function () {
        set_schema_sizes();
    });

    $(document).on('click', '.sinek-circle', function (e) {
        e.preventDefault();
        sinek_circle_open($(this));
    });

    function sinek_circle_open($this) {
        $('.sinek-circle:not(.circle-' + $this.data('circle-id') + ')').css('opacity', 0);
        $this.find('.sinek-circle-title').css('opacity', 0);

        // Wait for fade out not selected circles
        setTimeout(function () {
            // Prepare to animate
            var offset_top = $this.offset().top - $('body').scrollTop();
            var styles = $this.getStyleObject();
            var clone = $this.clone().css(styles);
            clone.css({
                'background-color': $this.css('border-color'),
                'top': offset_top,
                'left': $this.offset().left,
                'position': 'fixed',
                'z-index': 9,
            }).appendTo($('#sinek-container-fluid'));

            // Wait for the clone to animate and start growing circles
            var full_screen_circle_size = ($(window).width() > $(window).height()) ? $(window).width() * 3 : $(window).height() * 3;
            setTimeout(function () {
                clone.css({
                    width: full_screen_circle_size + 'px',
                    height: full_screen_circle_size + 'px',
                    top: -((full_screen_circle_size - offset_top) / 2),
                    left: -((full_screen_circle_size - $this.offset().left) / 2),
                    cursor: 'default'
                });
                // Get page content
                $('#main-content').load($this.attr('href') + ' #main-content', function () {
                    $('#sinek-container-fluid').css({
                        'position': 'fixed',
                        'z-index': 99
                    });
                    setTimeout(function () {
                        $('#sinek-container-fluid').fadeOut();
                    }, 750);
                    // Set URL
                    history.pushState({}, "", $this.attr('href'));
                });
            }, circle_animation_time);

        }, circle_animation_time);
    }

    window.addEventListener("popstate", function (e) {
        if (window.history.state !== null) {
            window.location.href = e.target.location.href;
        }
    });

    function set_schema_sizes() {
        var size = $(window).width();
        if (size > 1200) {
            size = 1200;
        }
        $('.sinek-container').css({
            height: size,
            width: size,
        });

        set_schema_titles_position();
    }

    function set_schema_titles_position() {
        /*
         * VERTICAL CENTER
         */
        $('.sinek-circle').each(function () {
            var circle_h = $(this).outerHeight(true);
            var title_h = $(this).find('.sinek-circle-title').outerHeight(true);
            var title_top = (circle_h / 2) - (title_h);
            $(this).find('.sinek-circle-title').css('top', title_top);
        });

        /*
         * HORIZONTAL CENTER
         */
        var nb_circle = $('.sinek-circle').length;
        for (var i = 0; i < nb_circle; i++) {
            var current_circle = $('.sinek-circle').eq(i);
            var current_circle_w = current_circle.outerWidth(true);
            var current_circle_title = current_circle.find('.sinek-circle-title');
            var current_circle_title_w = current_circle_title.outerWidth(true);
            var current_circle_title_left = 0;
            var next_circle = $('.sinek-circle').eq((i + 1));

            // Horizontal center if not last circle
            if (next_circle.length) {
                var circles_free_space = ((current_circle_w - next_circle.outerWidth(true)) / 2);
                var border_width = current_circle.css('border-width').replace(/px/, '');
                current_circle_title_left = (current_circle_w - circles_free_space) + (circles_free_space / 2) - (current_circle_title_w / 2);
                current_circle_title_left = current_circle_title_left - border_width;
            }
            current_circle_title.css('left', current_circle_title_left);
        }
    }

    $.fn.getStyleObject = function () {
        var dom = this.get(0);
        var style;
        var returns = {};
        if (window.getComputedStyle) {
            var camelize = function (a, b) {
                return b.toUpperCase();
            };
            style = window.getComputedStyle(dom, null);
            for (var i = 0, l = style.length; i < l; i++) {
                var prop = style[i];
                var camel = prop.replace(/\-([a-z])/g, camelize);
                var val = style.getPropertyValue(prop);
                returns[camel] = val;
            }
            return returns;
        }
        if ((style = dom.currentStyle) === true) {
            for (var x in style) {
                returns[x] = style[x];
            }
            return returns;
        }
        return this.css();
    };

})(jQuery);